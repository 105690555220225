.previewgroup-ui {
    .prod-head{
        font-size: 22px;
        font-weight: 600;
        text-align: left;
    }
    .prod-price{
        font-size: 18px;
        font-weight: bold;
        color: #14A248;       
    }
    .previewGroupOptionImage {
        width: 15px !important;
    }
    .custom-head{
        font-size: 19px;
        font-weight: 600;
    }
    .toppings-ui{
        border: 1px solid #7C7C7C;
        border-radius: 10px;
    }
    .toppings-price{
        font-size: 19px;
        font-weight: bold;
        color: #14A248;
    }
    .crust-ui{
        font-size: 15px;
        font-weight: lighter;
        border: 1px solid #7C7C7C;
        border-radius: 10px;
        justify-content: center;
        text-align: center;
        width: 108px;
        height: 92px;
    }
    .crust-ui-select{
        font-size: 15px;
        font-weight: 500;
        border: 2px solid #7C7C7C;
        border-radius: 10px;
        justify-content: center;
        text-align: center;
        width: 108px;
        height: 92px;
        background-color: #DDF4FF;
        z-index: -1;
        margin-top: -20px;
    }
    .crust-price-select{
        font-size: 17px;
        font-weight: 500;
        color: #14A248;
    }
    .select-check{
        z-index: 1; 
        margin-top: -22px;                 
    }
    .select-topping{
        position: relative;
        z-index: -1;
        margin-top: -24px;
    }
    .select-topping-check{
        z-index: 99;        
    }
    .custom-footer{        
        position: fixed;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
    }
    .total-price{
        font-size: 24px;
        font-weight: bold;
        color: #14A248;
        background-color: white;        
        text-align: center;
    }
    .custom-add{
        font-size: 24px;
        font-weight: 500;
        background-color: #14A248;
        color: white;
        text-align: center;       
    }
    .font-style-tmp1
    {
        font-size: 12px;
        margin-top: 10px;
    }
    .font-style-tmp2
    {
        font-size: 12px;
        margin-top: 15px;
    }
    .font-style-tmp3
    {
        font-size: 12px;
    }
}