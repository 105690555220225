.header-ui{

.logo-ui {
    width: 150px; 
  }
img {
  width: 50%;
}
.titlestyle {
  width:100%;
  font-size: 20px;
  text-align: right;
 
  color:  #509E2F;
}
.headerheight {
  height: 60px;
}


.drawer-leftmenu {
  position: relative;
  z-index: 1;
    .MuiDrawer-paper {
      padding-top: 60px;
      width: 240px !important;
      overflow: hidden;   
  }
}

.AppBar{
  position: fixed;
  z-index: 2;
}
  
}
