.productcustom-ui{
    .btn-style{
        width:310px;
    }
    .ctitle-font {
        font-size: 14px;
        font-weight: bold; 
        background-color: #F1F1F1;
        color: #509E2F;
      }
    .alignright {
        float: right;
    }
    .image{
        height: 40px;
        width: 40px;
        border-radius: 50%;
    }
    .text-ui{
        font-size: 14px;
    }

    .popper-menu-item {
        padding-left: 7px !important;
        padding-right: 7px !important;
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        cursor: pointer !important;
    }
    .custom-head{
        position: fixed;
        z-index: 2;
        top: 0px;
        width: 500px;
    } 
    .scroll-ui{
        overflow-y: auto !important; 
        max-height:100vh;
    }  
}
.boxwidth {
    width: 500px;
}
