.restaurantcontainer-ui{
  position: absolute;
  z-index: -1;
   .breadcrumb-ui{
      color: #509E2F;
     
   }
   .custom-link:hover {
       color: #509E2F;
       text-decoration-color: #509E2F;
    }
    .titlefont {     
      background-color:white;       
    }
    .custom-head{
      position: fixed;
      z-index: 2;
      top: 78px;   
      width: 100%; 
    }  
    .scroll-ui{        
      overflow-y: auto !important; 
      max-height:95vh;
    }  
}
