.category-ui{
    height: 200px;
    .margintop {
        margin-top: 60px;
    }
    .comboStyle {
        width: 220px;
    }
    .camera-align {
        padding-top: 4px !important;
        margin-top: -25px !important;
    }
    .boxwidth {
        width: 500px;
    }  
    img{
        width: 100%;
        height: 350px;
    }
    .custom-head{
        position: fixed;
        z-index: 2;
        top: 0px;
        width: 500px;
    } 
    .scroll-ui{
        overflow-y: auto !important; 
        max-height:100vh;
    }  
    .custom-footer{        
        position: fixed; 
        z-index: 1;      
        bottom: 0px;
        right: 0px; 
        width: 500px;
        background-color: #f8f3ef;           
    }
    .dateStyle {
        width: 460px;
    }

}