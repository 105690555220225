.buttonColor {
  background-color: #509E2F;
}
.logo {
  height: 60px;
  background: white;
  width: 80px;
  display: flex;
  text-align: center;
}
/* img {
  width: 60%;
} */
.login-row {
  height: 73px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: white;
}
.align-name {
  text-align: center; 
  font-size: 20px;
  color: #509E2F;
  padding-left: 60px;
  }
  .error {
    color: red;
    font-size: 14px;
    margin-left: 15px;
  }
