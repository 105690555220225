.imageslide-ui{
    .slidewidth {
        width:500px;
        height: 500px;
    }
    img{
        width: 500px;
        height: 450px;        
    }
}
