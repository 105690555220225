.storeprofile-ui{    
    .custom-head{
        position: fixed;
        z-index: 2;
        top: 78px;   
        width: 100%; 
    }  
    .scroll-ui{
        overflow-y: auto !important; 
        max-height:85vh;
    } 
}