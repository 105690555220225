.storegeneral-ui{
    .verified {
        color: green;
        font-weight: bold;
    }
    // .profile-photo {
    //     margin:left;
    //     width: 100px;
    //     height: 100px;
    // }
    img{
        width: 100%;
        height: 350px;
    }
    .camera-align {
        padding-top: 4px !important;
        margin-top: -25px !important;
    }
    
}
