.productform-ui{
    .margintop {
        margin-top: 60px;
    }
    // .imgview{
    //     height: 1%;
      
    // }
    // .comboStyle {
    //     width: 220px;
    // }
    .image-photo {
        margin:left;
        width: 70px;
        height: 70px;
    }
    img{
        width: 100%;
        height: 350px;
    }
    .camera-align {
        padding-top: 4px !important;
        margin-top: -25px !important;
    }
    .textareastyle {
        width: 350px;
    }
    // .displaybottom-ui{
    //     position: absolute;
    //     bottom: 0px;
    //     right: 25px;        
    // } 
    .boxwidth {
        width: 500px;
    } 
    .custom-head{
        position: fixed;
        z-index: 2;
        top: 0px;
        width: 500px;
    }  
    .scroll-ui{
        overflow-y: auto !important; 
        max-height:100vh;
    } 
    .custom-footer{        
        position: fixed; 
        z-index: 1;      
        bottom: 0px;
        right: 0px; 
        width: 500px;
        background-color: #f8f3ef;           
    }   
}