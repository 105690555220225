.menulist-ui{
    background-color: #F5F6FA;
    overflow: scroll;
    .title-font {
        font-size: 14px;
        font-weight: bold; 
        background-color: white; 
        color:#000000;
      }
      .fas{
        color: #509E2F;
        font-size: 20px; 
      }
    .menuhead-ui{
        font-size: 20px;
        font-weight: 500;
    }
    .alignright {
        float: right;
    }
    .upload-ui{
        color: #509E2F;
        font-size: 11px;
        font-weight: bold;        
        text-align: end;       
    }

    
    .togglestyle {
        margin-top: 4px;
    }
    .selected {
        background-color: #F1F1F1;
        font-weight: bold;
    }
    .unselected {
        color: black;
    }
    
    img{
        // width: 100%;
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }
    .edit-ui{
        // background-color: blueviolet;
        display: grid;
        text-align: right;             
        // height: 100px;
    }
    .search-ui{        
        text-align: right;
        display: block;        
    }
    .no-ui{
        display: grid;        
        justify-content: center;
    }
    .customize-ui{
        color: #509E2F;
        font-size: 11px;
        font-weight: bold;
    }
    .text-ui{
        font-size: 14px;
    } 
    .button-align{
        text-align: end;
    }   
    .dot-ui{
        margin-top: -20px !important;
    }
    .card{
        height: 500px;
        display: flex;
        flex-direction: column;        
    }
    .scrollable{
        overflow-y: scroll !important;
        max-height: 490px;
        scroll-behavior: auto;
        flex: 1;

    }
    .custom-head{
        position: sticky;
        z-index: 2;
        top: 0px;   
        width: 100%;    
    } 
    .scroll-ui{
        overflow-y: auto !important; 
        max-height:85vh;
    } 
    .titlefont {     
        background-color:white;       
      }
}

.popper-menu-item {
    padding-left: 7px !important;
    padding-right: 7px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    cursor: pointer !important;
}