.upload-ui{
    .photocapture-style {
        .divider{
            border-top: 1px solid #ddd;
            margin-top: 10px;
            padding-top: 10px;
        }
    }
    .numberlist{
        font-size: 13px;
        font-weight: 500;
    }
    .float-right {
        float: right !important;
      }
      .uploadhead-ui{
          font-size: 16px;
          font-weight: bold;         
      }
    .boxwidth{
        width: 500px;
    }
    .custom-head{
        position: fixed;
        z-index: 2;
        top: 0px;
        width: 500px;
    }
    .scroll-ui{
        overflow-y: auto !important; 
        max-height:100vh;
    } 
}
